import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState
} from 'react'
import { fetchAuthSession, signOut } from '@aws-amplify/auth'

const AuthContext = createContext<{
  getBearer: () => Promise<string>
  userEmail?: string
} | null>(null)

interface AuthProviderProps {
  children: ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [userEmail, setUserEmail] = useState<string>()
  const fetchToken = async (): Promise<string> => {
    try {
      const session = await fetchAuthSession()
      const token = session.tokens!.idToken!.toString()!

      if (typeof session.tokens?.idToken?.payload.email === 'string') {
        setUserEmail(session.tokens.idToken.payload.email)
      }

      return `Bearer ${token}`
    } catch (error) {
      console.error('Error fetching JWT token', error)
      await signOut()
    }
    return ''
  }

  const getBearer = useCallback(async (): Promise<string> => {
    return fetchToken()
  }, [])!

  const contextValue = { getBearer, userEmail }

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}
export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider')
  }
  return context
}
