import ModeEditIcon from '@mui/icons-material/ModeEdit'
import ClearIcon from '@mui/icons-material/Clear'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import React, { useState } from 'react'

import { SnackbarError } from '../CaseDetails/snackbarError'
import { useCaseContext } from '../Contexts'
import { User } from '../Contexts/types'
import { DebugBorder } from '../utils/debugBorder'
import { iconsProps } from '../CaseDetails'

export const Assignee = React.memo(({ assignee }: { assignee?: string }) => {
  const { fetchAssignableUsers, updateAssignee, deleteAssignee } =
    useCaseContext()

  const [editMode, setEditMode] = useState(false)
  const [selectedUser, setSelectedUser] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [usersList, setUsersList] = useState<User[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const handleEditAssignee = async (): Promise<void> => {
    try {
      setEditMode(true)
      setIsLoading(true)

      const users = await fetchAssignableUsers()
      if (users?.length > 0) {
        setUsersList(users)
      }
    } catch (error) {
      setErrorMessage('Error while fetching assignable users')
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteAssignee = async (): Promise<void> => {
    try {
      setSelectedUser('unassigned')
      setIsLoading(true)
      setEditMode(false)

      await deleteAssignee()
    } catch (error) {
      setErrorMessage('Failed to delete assigned user')
    } finally {
      setIsLoading(false)
    }
  }

  const handleAssigneeChange = async (
    event: SelectChangeEvent<string>
  ): Promise<void> => {
    try {
      setSelectedUser(event.target.value)
      setIsLoading(true)
      setEditMode(false)

      if (event.target.value === 'unassigned') {
        await deleteAssignee()
      } else {
        await updateAssignee(event.target.value)
      }
    } catch (error) {
      setErrorMessage('Failed to change assigned user')
    } finally {
      setIsLoading(false)
    }
  }

  const placeholderText = assignee ? 'Assigned user' : 'Assign user'

  return (
    <DebugBorder>
      {errorMessage && (
        <SnackbarError
          message={errorMessage}
          onClose={() => setErrorMessage('')}
        />
      )}

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {!editMode && (
          <>
            <Typography component='span' className='case-details__assignee'>
              {assignee || 'Unassigned'}
            </Typography>
            <IconButton
              className='case-details__edit-assignee-icon'
              onClick={handleEditAssignee}
              sx={{ padding: '5px', marginLeft: '2px' }}
            >
              <ModeEditIcon sx={{ color: '#0080f0', ...iconsProps }} />
            </IconButton>
          </>
        )}

        {editMode && usersList.length > 0 && (
          <FormControl sx={{ width: '95%' }}>
            <InputLabel size='small' id='assigned-user-label'>
              {placeholderText}
            </InputLabel>
            <Select
              disabled={isLoading}
              fullWidth
              size='small'
              value={selectedUser || assignee || ''}
              onChange={handleAssigneeChange}
              onClose={(e) => {
                if ((e.target as HTMLElement).role !== 'option') {
                  setEditMode(false)
                }
              }}
              label={placeholderText}
              className='case-details__assignee-select'
            >
              <MenuItem key='unassigned' value='unassigned'>
                Unassigned
              </MenuItem>
              {usersList.map(({ email }, index) => (
                <MenuItem key={`${email}--${index}`} value={email}>
                  {email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {assignee && (
          <IconButton
            className='case-details__delete-assignee-icon'
            onClick={handleDeleteAssignee}
            sx={{ padding: '5px' }}
          >
            <ClearIcon sx={{ color: '#0080f0', ...iconsProps }} />
          </IconButton>
        )}
      </Box>
    </DebugBorder>
  )
})
