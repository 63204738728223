import { AddCircleOutline } from '@mui/icons-material'
import { Box, Button, Grid, IconButton } from '@mui/material'
import React, { FC, useState } from 'react'
import { AddCommentForm } from './addCommentForm'
import { Comment } from '../../../Contexts/types'
import { DebugBorder } from '../../../utils/debugBorder'
import { SingleComment } from './singleComment'
import { ExpandLessIcon, ExpandMoreIcon } from '../Shared'

interface CommentSectionProps {
  comments: Comment[]
}

const iconsProps = {
  width: 15,
  height: 15
}

const INITIAL_ENTRIES_TO_SHOW: number = 5

export const CommentsSection: FC<CommentSectionProps> = ({ comments }) => {
  const [isCommentFormOpen, setIsCommentFormOpen] = useState<boolean>(false)
  const [isListOpen, setIsListOpen] = useState<boolean>(false)

  const getExpandIcon = () => (isListOpen ? ExpandLessIcon : ExpandMoreIcon)
  const getExpandText = () => (isListOpen ? 'Show less' : 'Show more')
  const getFilteredComments = () =>
    isListOpen ? comments : comments.slice(0, INITIAL_ENTRIES_TO_SHOW)
  const getEntryText = (a: number) => (a === 1 ? a + ' entry' : a + ' entries')

  const filteredComments: Comment[] = getFilteredComments()
  const expandIcon: React.JSX.Element = getExpandIcon()
  const expandText: string = getExpandText()
  const entryAmount = comments.length
  const shouldShowMoreButton: boolean =
    comments.length > INITIAL_ENTRIES_TO_SHOW
  const entryText = getEntryText(entryAmount)

  return (
    <DebugBorder>
      <Grid container>
        <Grid item xs={12}>
          <Box
            className='common-item-list--bold'
            position='relative'
            display='flex'
            sx={{ alignItems: 'center', gap: 1 }}
          >
            <div className='common-item-list--bold'>Comments ({entryText})</div>
            {!isCommentFormOpen && (
              <IconButton
                onClick={() => setIsCommentFormOpen(true)}
                sx={{ ...iconsProps, mt: 1 }}
              >
                <AddCircleOutline sx={{ color: '#0080f0', ...iconsProps }} />
              </IconButton>
            )}
            {shouldShowMoreButton && (
              <Button
                sx={{ color: '#596780', mt: 1, mr: 1 }}
                variant='text'
                onClick={() => {
                  setIsListOpen(!isListOpen)
                }}
                startIcon={expandIcon}
              >
                {expandText}
              </Button>
            )}
          </Box>
        </Grid>
        <AddCommentForm
          isCommentFormOpen={isCommentFormOpen}
          onClose={() => setIsCommentFormOpen(false)}
        />
        {filteredComments.map((comment, index) => (
          <Grid key={index} item xs={12}>
            <SingleComment comment={comment} />
          </Grid>
        ))}
      </Grid>
    </DebugBorder>
  )
}
