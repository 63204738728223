import React, { FC } from 'react'
import './alarmCardDetail.sass'
import { Grid } from '@mui/material'

interface AlarmCardDetailProps {
  label: string
  value: string
}

export const AlarmCardDetail: FC<AlarmCardDetailProps> = ({ label, value }) => {
  return (
    <div className='alarm-card-detail'>
      <Grid container>
        <Grid item xs={4}>
          <div className='alarm-card-detail-key' data-testid={`${label}-label`}>
            {label}
          </div>
        </Grid>
        <Grid item xs={8}>
          <div className={'alarm-card-detail-value'}>{value}</div>
        </Grid>
      </Grid>
    </div>
  )
}
