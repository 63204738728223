import { Box, Button, Grid } from '@mui/material'
import React, { FC, useState } from 'react'
import { History } from '../../../Contexts/types'
import { DebugBorder } from '../../../utils/debugBorder'
import './historySection.sass'
import { SingleHistoryEntry } from './singleHistoryEntry'
import { ExpandLessIcon, ExpandMoreIcon } from '../Shared'

const INITIAL_ENTRIES_TO_SHOW = 5

interface HistorySectionProps {
  historyEntries: History[]
}

export const HistorySection: FC<HistorySectionProps> = ({ historyEntries }) => {
  const [isListOpen, setIsListOpen] = useState(false)

  historyEntries.sort((one: History, other: History) => {
    if (one.created > other.created) {
      return -1
    }
    if (one.created < other.created) {
      return 1
    }
    return 0
  })

  const getExpandIcon = () => (isListOpen ? ExpandLessIcon : ExpandMoreIcon)
  const getExpandText = () => (isListOpen ? 'Show less' : 'Show more')
  const getFilteredHistoryEntries = () =>
    isListOpen
      ? historyEntries
      : historyEntries.slice(0, INITIAL_ENTRIES_TO_SHOW)
  const getEntryText = (a: number) => (a === 1 ? a + ' entry' : a + ' entries')

  const filteredHistoryEntries: History[] = getFilteredHistoryEntries()
  const expandIcon: React.JSX.Element = getExpandIcon()
  const expandText: string = getExpandText()
  const entryAmount = historyEntries.length
  const shouldShowMoreButton: boolean =
    historyEntries.length > INITIAL_ENTRIES_TO_SHOW
  const entryText = getEntryText(entryAmount)

  return (
    <DebugBorder>
      <Grid item sm={12} className={'title'}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              className='common-item-list--bold'
              position='relative'
              display='flex'
              sx={{ alignItems: 'center', gap: 1 }}
            >
              <div className='common-item-list--bold'>
                History ({entryText})
              </div>

              {shouldShowMoreButton && (
                <Button
                  sx={{ color: '#596780', mt: 1 }}
                  variant='text'
                  onClick={() => {
                    setIsListOpen(!isListOpen)
                  }}
                  startIcon={expandIcon}
                >
                  {expandText}
                </Button>
              )}
            </Box>
          </Grid>
          {filteredHistoryEntries.map((historyEntry, index) => (
            <Grid key={index} item xs={12}>
              <SingleHistoryEntry historyEntry={historyEntry} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </DebugBorder>
  )
}
