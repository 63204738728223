import { Case, Comment, History } from '../../Contexts/types'

export interface UseCaseDetailsContentHookProperties {
  sortedComments: Comment[]
  sortedHistoryEntries: History[]
}

// TODO: we could remove useCaseDetailsContent and prepare sortedComments
//  and sortedHistoryEntries right in the needed components
export const useCaseDetailsContent = (
  activeCase: Case
): UseCaseDetailsContentHookProperties => {
  const sortedComments = activeCase?.comments.sort(
    (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
  )

  const sortedHistoryEntries = activeCase?.history?.sort(
    (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
  )

  return {
    sortedComments,
    sortedHistoryEntries
  }
}
