import { useCaseContext } from '../../../Contexts'
import React, { FormEvent, useState } from 'react'
import { CaseDetailsContentTagsProps } from './caseDetailsContentTags'

interface UseCaseDetailsContentTagsProperties {
  editTag: string
  editMode: boolean
  isLoading: boolean
  startEditMode: () => void
  handleCancel: () => void
  handleSaveNewTag: (e: FormEvent) => Promise<void>
  handleFieldChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
}

export const useCaseDetailsContentTags = (
  props: CaseDetailsContentTagsProps
): UseCaseDetailsContentTagsProperties => {
  const { activeCase, setSnackbarMessage } = props

  const { postTags } = useCaseContext()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [editTag, setEditTag] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const startEditMode = () => {
    setEditMode(true)
  }

  const handleCancel = () => {
    setEditMode(false)
    setIsLoading(false)
    setEditTag('')
  }

  const handleSaveNewTag = async (e: FormEvent) => {
    e.preventDefault()
    const tagRegex = /^[a-zA-Z0-9\s-]+$/
    try {
      setIsLoading(true)
      if (!activeCase?.id) {
        throw Error('Invalid case id')
      }
      if (!editTag || !editTag.length) {
        throw Error('Tag can not be empty')
      }
      if (editTag.length >= 256 || !tagRegex.test(editTag)) {
        throw Error(
          'Tag validation failed. Only letters, numbers, and dash are allowed.'
        )
      }

      await postTags({
        id: activeCase?.id,
        tag: editTag.toLowerCase()
      })
      setEditTag('')
      setIsLoading(false)
      setEditMode(false)
    } catch (error) {
      setEditMode(true)
      setIsLoading(false)
      if (error instanceof Error) {
        setSnackbarMessage(error.message)
      } else {
        setSnackbarMessage('Failed to add tag')
      }
    }
  }

  function handleFieldChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    setEditTag(event.target.value)
  }

  return {
    editTag,
    editMode,
    isLoading,
    startEditMode,
    handleCancel,
    handleSaveNewTag,
    handleFieldChange
  }
}
