import React, { useMemo } from 'react'
import { PumpImage } from './PumpImage/pumpImage'
import { useCaseContext } from '../../Contexts'
import { StatusSwitch } from './StatusSwitch/statusSwitch'
import { getLocalizedDateString } from '../../utils/formatDateString'
import './pumpInfoPanel.sass'
import { Case, CaseStatusEnum } from '../../Contexts/types'
import { PumpInfoPanelFields } from './PumpInfoPanelFields/PumpInfoPanelFields'

export interface Props {
  pump: Case | undefined
}

const attributeLabels: { [key: string]: string } = {
  pumpCountryCode: 'Country Code',
  customerId: 'Customer ID',
  organizationId: 'Organization ID',
  organizationName: 'Organization',
  pumpApplication: 'Application',
  pumpId: 'Pump ID',
  pumpLocation: 'Location Name',
  pumpManufacturer: 'Manufacturer Pump',
  pumpName: 'Pump Name',
  pumpRatedPower: 'Rated Power (kW)',
  pumpSensorsId0: 'Sensor ID',
  pumpSensorsSerialNumber0: 'Serial Number Sensor',
  pumpSerialNumber: 'Serial Number Pump',
  pumpSize: 'Type Size',
  pumpTechnicalLocation: 'Technical Location Name',
  pumpTypeSeries: 'Type Series',
  pumpType: 'Type',
  pumpNumberOfBlades: 'Blades',
  pumpRatedSpeed: 'Rated Speed (rpm)', // rename this into 'Rated Speed Pump (rpm)'
  firstAlarmOccurrence: 'First Occurrence',
  lastAlarmOccurrence: 'Last Occurrence',
  // Following attributes are currently not available in IM (waits for Guard endpoint)
  customerName: 'Customer Name',
  pumpVariableSpeed: 'Variable Speed',
  ratedSpeedMotor: 'Rated Speed Motor (rpm)',
  pumpRatedFlow: 'Flow (m³/h)',
  pumpRatedHead: 'Rated Head (m)',
  pumpNumberOfStages: 'Stages',
  pumpNumberOfEntries: 'Entries'
}

const TAG_MUTED = 'muted'

export const PumpInfoPanel = ({ pump }: Props) => {
  const {
    updateCase: updateExistingCase,
    postTags,
    removeTagFromCase
  } = useCaseContext()

  let {
    title,
    tags,
    id,
    description,
    attributes,
    status,
    assignee,
    firstAlarmOccurrence,
    lastAlarmOccurrence
  } = pump ?? ({} as Case)

  const pumpInfoAttributes = useMemo(() => {
    const firstAlarmOccurrenceValue =
      getLocalizedDateString(firstAlarmOccurrence)
    const lastAlarmOccurrenceValue = getLocalizedDateString(lastAlarmOccurrence)

    attributes.push({
      key: 'firstAlarmOccurrence',
      value: firstAlarmOccurrenceValue
    })
    attributes.push({
      key: 'lastAlarmOccurrence',
      value: lastAlarmOccurrenceValue
    })

    attributes.push({ key: 'pumpId', value: id })

    return attributes
  }, [attributes, firstAlarmOccurrence, id, lastAlarmOccurrence])

  const getAttributeValues = (attributeName: string) => {
    for (const attribute of pumpInfoAttributes) {
      if (attribute.key === attributeName) {
        return attribute.value
      }
    }
    return '-'
  }

  const getLabelText = (attributeName: string) => {
    return attributeLabels[attributeName]
  }

  const onChangeMuteToggle = () => {
    const tagPayload = {
      id,
      tag: TAG_MUTED
    }

    tags.includes(TAG_MUTED)
      ? removeTagFromCase(tagPayload)
      : postTags(tagPayload)
  }

  return (
    <div className='pump-info-panel'>
      <PumpImage
        pumpId={id}
        organizationId={
          attributes?.find((attr) => attr.key === 'organizationId')?.value || ''
        }
      />
      <div className='pump-info-panel__toggles'>
        <StatusSwitch
          label={'Closed'}
          onChange={() =>
            updateExistingCase({
              id,
              title,
              description,
              status:
                status === CaseStatusEnum.CLOSED
                  ? CaseStatusEnum.OPEN
                  : CaseStatusEnum.CLOSED,
              assignee
            })
          }
          isChecked={status === 'CLOSED'}
        />
        <StatusSwitch
          label={'Muted'}
          onChange={onChangeMuteToggle}
          isChecked={tags.includes(TAG_MUTED)}
        />
      </div>
      <div className='pump-info-panel__info-fields'>
        <PumpInfoPanelFields
          getFieldValue={getAttributeValues}
          getLabelText={getLabelText}
        ></PumpInfoPanelFields>
      </div>
    </div>
  )
}
